.app__header{
    background-color: var(--color-black);
}

.app__header-h1 {
    font-family: var(--font-base);
    color: var(--color-white);
    text-transform: camel-case;
    line-height: 90px;
    font-size: 80px;
}  

.app__header-img img{
    width: 80%;
}


@media screen and (max-width: 650px) {
    .app__header-h1 {
        font-size: 50px;
        letter-spacing: 0.04em;
        line-height: 65px;

    }

    
}
.p__playfair {
    font-family: var(--font-alt);
    font-weight: 500;
    font-size: large;
}