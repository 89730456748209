.app__specialMenu {
    flex-direction: column;
    background: var(--color-black);
}

.app__specialMenu-title {
    margin-bottom: 2rem;
    text-align: center;
}

.app__specialMenu-menu {
    width: 100%;

    margin: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}

.app__specialMenu-menu_heading {
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 45px;
    line-height: 58.5px;
    letter-spacing: 0.04em;

    color: var(--color-white);

}

.app__specialMenu-menu_wine{
    flex: 1; 
    width: 100%;
    flex-direction: column;
}

.app__specialMenu-menu_cocktails {
    flex: 1; 
    width: 100%;
    flex-direction: column;
}

.app__specialMenu-menu_img {
    width: 410px;
    margin: 0 2rem;
}

.app__specialMenu-menu_img img{
    width: 100%;
    height: auto;
}

.app__specialMenu-menu_items {
    display: flex;
    flex-direction: column;

    margin: 2rem 0; 

    width: 100%;
}
.censo-dropdown p{
    font: var(--font-base);
    color: var(--color-golden); 
    font-size: large;
}

@media screen and (max-width: 2000px) {

    
}

@media screen and (max-width: 1150px) {
    .app__specialMenu-menu {
        flex-direction: column;
        align-items: center;
        width: 100%; 
    }
    .app__specialMenu-menu_img {
        margin: 3rem 0;
    }
}

@media screen and (max-width: 650px) {
    .app__specialMenu-menu_img{
        width: 100%;
    }
    .app__specialMenu-menu_heading{
        font-size: 35px;
        line-height: 48.5px;
    }
}

/* src/container/Menu/SpecialMenu.css */

.app__navbar-links {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 2rem; 
    margin: 2rem; /* Remover margin padrão */
}

.app__navbar-links li {
    font-size: 18px;
    margin: 0 1rem; /* Espaço entre as guias */
    cursor: pointer;
    color: var(--color-white); /* Cor do texto */
    transition: color 0.3s; /* Transição suave para cor */
}

.app__navbar-links li:hover {
    font-weight: bolder; /* Negrito ao passar o mouse */
    color: var(--color-golden); /* Cor ao passar o mouse */
}

.app__navbar-links .active {
    font-weight: bolder; /* Negrito para a guia ativa */
    color: var(--color-golden); /* Cor para a guia ativa */
}


