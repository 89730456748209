.app__navbar{
    width:100%;
    display: flex; 
    justify-content: space-between;
    align-items: center;
    background: var(--color-black);
    
    padding: 1rem 2rem;
}

.app__navbar-logo{
    display: flex; 
    justify-content: flex-start;
    align-items: center;
    
}

.app__navbar-logo img{
    width: 150px;
}

.app__navbar-links{
    flex: 1; 
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.app-navbarLinks{
    flex: 1; 
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.app-navbarLinks li {
    cursor: pointer;
    margin: 0 2rem;
}


.app-navbarLinks li:hover {
    font-weight: bolder;
    color: var(--color-golden); 
}

.p__quicksand {
    color: var(--color-white);
    font-size: large;
    font-family: var(--font-alt);
}

.app__navbar-login {
    display: flex; 
    justify-content: flex-end;
    align-items: center;
}

.app__navbar-login a {
    margin: 0 1rem;
    text-decoration: none;
    transition: 0.5s ease;

}

.app__navbar-login a:hover{
    border-bottom: 1px solid var(--color-golden);
}

.app__navbar-login div {
    width: 1px; 
    height: 30px;
    background-color: var(--color-grey);
}

.app__navbar-smallscreen {
    display: none; 

}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0; 
    left: 0; 
    width: 100%;
    height: 100vh;
    background: var(--color-black);
    transition: 0.5s ease;

    flex-direction: column;
    z-index: 5;

}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: var(--color-golden);
    cursor: pointer; 

    position: fixed;
    top: 20px;
    right: 20px; 
}

.app__navbar-smallscreen_links {
    list-style: none;
}

.app__navbar-smallscreen_links li {
    margin: 2rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size: 2rem; 
    text-align: center; 
    font-family: var(--font-base);
}

.app__navbar-smallscreen_links li:hover {
    color: var(--color-white);
    
}

@media screen and (max-width: 2000px) {
    .app__navbar-logo img{
        width: 150px; 
    }
}


@media screen and (max-width: 1150px) {
    .app-navbarLinks {
        display: none; 
    }
    .app__navbar-smallscreen {
        display: flex; 
    }
}

@media screen and (max-width: 650px) {
    .app__navbar {
        display: flex;
        justify-content: space-between; /* Espaço máximo entre o logo e o menu */
        align-items: center;
        padding: 1rem;
    }

    .app__navbar-login {
        display: none; /* Esconder login para telas pequenas */
    }

    .app__navbar-logo img {
        width: 110px; /* Ajustar o tamanho da logo */
    }

    .app__navbar-smallscreen {
        display: flex;
        justify-content: flex-end; /* Alinha o menu de hambúrguer à direita */
        flex-grow: 1; /* O menu preenche o espaço restante */
    }

    .app__navbar-smallscreen svg {
        margin: 0;
    }
}
